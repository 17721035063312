.svg {
    fill: currentColor;
    height: auto;
    transform-origin: center;
    width: 100%;
    letter-spacing: 2px;
    font-weight: bold;
    color: white;
    cursor: default;
    user-select: none;
    text-transform: uppercase;
}