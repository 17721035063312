.translateIn {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.translateOut {
    transform: translateY(-40%);
    opacity: 0;
    visibility: hidden;
}

.expand {
    max-height: 500px !important;
}

.collapse {
    max-height: 0px !important;
}