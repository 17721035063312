@keyframes circle1 {
    0% { transform: rotate(0) translate(-142px) rotate(0); }
    50% { transform: rotate(180deg) translate(-142px) rotate(180deg); }
    100% { transform: rotate(0deg) translate(-142px) rotate(0deg); }
}

@keyframes circle2 {
    0% { transform: rotate(180deg) translate(-103px) rotate(180deg); }
    50% { transform: rotate(0) translate(-103px) rotate(0); }
    100% { transform: rotate(180deg) translate(-103px) rotate(180deg); }
}

@keyframes circle3 {
    0% { transform: rotate(-15deg) translate(-63px) rotate(-15deg); }
    50% { transform: rotate(150deg) translate(-63px) rotate(150deg); }
    100% { transform: rotate(-15deg) translate(-63px) rotate(-15deg); }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media (min-width: 768px) {
    @keyframes circle1 {
    0% { transform: rotate(0) translate(-399px) rotate(0); }
    50% { transform: rotate(180deg) translate(-399px) rotate(180deg); }
    100% { transform: rotate(0deg) translate(-399px) rotate(0deg); }
}

@keyframes circle2 {
    0% { transform: rotate(180deg) translate(-274px) rotate(180deg); }
    50% { transform: rotate(0) translate(-274px) rotate(0); }
    100% { transform: rotate(180deg) translate(-274px) rotate(180deg); }
}

@keyframes circle3 {
    0% { transform: rotate(-15deg) translate(-149px) rotate(-15deg); }
    50% { transform: rotate(150deg) translate(-149px) rotate(150deg); }
    100% { transform: rotate(-15deg) translate(-149px) rotate(-15deg); }
}
}

@keyframes spin-reverse {
    from {
        transform: rotate(360deg);
    }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1em));
}
}