@font-face {
    font-family: 'poppins';
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Thin.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 200;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-ExtraLight.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Medium.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 800;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-ExtraBold.ttf') format("truetype");
}

@font-face {
    font-family: 'poppins';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/poppins/Poppins-Black.ttf') format("truetype");
}